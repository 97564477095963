import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "site-content" }
const _hoisted_2 = { class: "section-content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-10 ml-md-auto mr-md-auto" }
const _hoisted_6 = { class: "rooms rooms--list" }
const _hoisted_7 = { class: "room__body box" }
const _hoisted_8 = { class: "room__header" }
const _hoisted_9 = { class: "room__title" }
const _hoisted_10 = { class: "room__excerpt" }
const _hoisted_11 = { class: "room__footer" }
const _hoisted_12 = { class: "room__img room__img-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_page_header, {
      image: "abandoned",
      title: "Meet The Team"
    }),
    _createVNode("main", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.staff, (person) => {
                  return (_openBlock(), _createBlock("div", {
                    key: person.url,
                    class: "room staff",
                    "data-aos": "fade-up",
                    "data-aos-duration": "800"
                  }, [
                    _createVNode("div", _hoisted_7, [
                      _createVNode("header", _hoisted_8, [
                        _createVNode("h2", _hoisted_9, [
                          _createVNode("a", {
                            href: '/meet-the-team/' + person.url,
                            innerHTML: person.name
                          }, null, 8, ["href", "innerHTML"])
                        ])
                      ]),
                      _createVNode("div", _hoisted_10, [
                        _createVNode("p", {
                          innerHTML: person.snippet
                        }, null, 8, ["innerHTML"])
                      ]),
                      _createVNode("footer", _hoisted_11, [
                        _createVNode("a", {
                          href: '/meet-the-team/' + person.url,
                          class: "btn btn-primary"
                        }, "Read More", 8, ["href"])
                      ])
                    ]),
                    _createVNode("figure", _hoisted_12, [
                      _createVNode("a", {
                        href: '/meet-the-team/' + person.url
                      }, [
                        _createVNode("img", {
                          src: person.image.file.url,
                          alt: person.name
                        }, null, 8, ["src", "alt"])
                      ], 8, ["href"])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}